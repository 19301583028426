$publicPath:'';

.orgSelect {
  /deep/.el-form-item__content {
    display: flex;
    span {
      margin-left: 5px;
      white-space: nowrap;
      color: #0aa665;
      cursor: pointer;
      user-select: none;
    }
  }
}
.form-lable {
  padding-left: 5px;
  border-left: 2px solid #0aa665;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  margin-bottom: 15px;
}
.nodeName {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  .nodeIndex {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
    margin-right: 15px;
    border: 1px solid rgb(97, 97, 97);
  }
}
.nodeInput {
  width: 200px;
  margin-right: 20px;
}
.nodeType {
  display: flex;
}
